// Effects
/*effect-underline*/
a.effect-underline {
  border: none;
  position: relative;
}
a.effect-underline:after {
  content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 7px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
}
a.effect-underline--grey:after {
  border-bottom: $mid-grey 1px solid;
}
a.effect-underline:hover:after {
  opacity: 1;
  transform: scale(1);
}
.border-hover{
    border:solid 1px rgba(0,0,0,0);
    cursor: pointer;
}
.border-hover:hover{
  border:solid 1px #e2e8f0;
}
