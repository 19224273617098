// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.header {
  background: transparent;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;

  &__no-bg {
    background-color: transparent;
    overflow: visible;
  }

  &__login {
    overflow: visible;
    @media screen and (max-width:$sm) {
      background-color: $brand-color;
    }
  }
}

.header-menu {
  ul {
    li {
      @media screen and (max-width:$sm) {
        font-size: 14px;
      }
    }
  }
  .block-contact p{
  @media screen and (max-width:$sm) {
    font-size: 14px;
  }
}
}

.header__flex {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: center;
}

.full-menu {
  @media screen and (max-width:$sm) {
    height: calc(var(--vh, 1vh) * 100) !important;
  }
}

.header__item {
  display: block;
}

.logo {
  position: relative;
  z-index: 40;
}

.mob_link {
  border: 1px solid #fff;
  padding: 10px 8px;
  border-radius: 3px;
  text-align: center;
}

.mobile-main-btns .mob_link:nth-child(1) {
  width: 100%;
}

.mobile-main-btns .mob_link:nth-child(2) {
  width: calc(50% - 0.5em);
  margin-right: 0.5em;
}

.mobile-main-btns .mob_link:nth-child(3) {
  width: calc(50% - 0.5em);
  margin-left: 0.5em;
}

.banner {
  height: calc(var(--vh, 1vh) * 100);
}

.banner {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 600px) {
    .banner {
        height: calc(var(--vh, 1vh) * 65);
    }
}


.banner_overlay{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
pointer-events: none;
&.banner_bottomRight{
      background: linear-gradient(351deg, rgba(0,0,0,0.804359243697479) 0%, rgba(5,3,3,0) 30%);
  }
  &.banner_bottomLeft{
background: linear-gradient(35deg, rgba(0,0,0,0.804359243697479) 6%, rgba(5,3,3,0) 40%);
  }
&.banner_top{
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5858718487394958) 100%);
  }
  &.banner_bottom{
    background: linear-gradient(0deg, rgba(0,0,0,0.5886729691876751) 0%, rgba(0,0,0,0) 64%, rgba(0,0,0,0) 100%);
  }

  }
