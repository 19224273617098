.bg{
  background-size: cover;
  background-position: center;

  &-fixed{
      @media screen and (min-width: $md) {
    background-attachment:fixed;
  }
}
}
.cover-image img{
  width: auto;
  min-height: 400px;
}

.mobile-img{
  background-size: cover;
  min-height: 300px;
}

.desk-bg-image{
  @media screen and (min-width: 1024px) {
      min-height: 80vh;
    }
}

.no-mobile-bg{

  @media screen and (max-width: $sm) {
      background-image: none !important;
    }

}

.feature-image{

  &__small{
    min-height: 450px;
  }

  &__medium{
    min-height: 600px;
  }

  &__large{
    min-height: 750px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  &__full:after {
    background: rgba(0, 0, 0, 0.5);
  }

  &__bottom:after {
    background: linear-gradient(0deg, rgba(0,0,0,0.5886729691876751) 0%, rgba(0,0,0,0) 64%, rgba(0,0,0,0) 100%);
  }

  &__top:after {
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5858718487394958) 100%);
  }
}
