// General stuff
img {
  height: auto;
  max-width: 100%;
  width: 100%;
}


.primary-bg{
  background-color: $brand-color;
}

.brand-text{
  color: $brand-color;
}

.image-overlay-bottom{
  position: relative;
}
.image-overlay-bottom:after{
  content: "";
position: absolute;
z-index: 0;
left: 0;
right: 0;
top: 0;
z-index: 2;
width: 100%;
height: 100%;
background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.588673)), color-stop(64%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0)));
background: linear-gradient(0deg, rgba(0, 0, 0, 0.588673) 0%, rgba(0, 0, 0, 0) 64%, rgba(0, 0, 0, 0) 100%);
}
