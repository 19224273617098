.slider-bottom {
  margin-bottom: -180px;
  @media (max-width: $md) {
    margin-bottom: -100px;
  }
}

.project-nav .hooper-slide div{
min-width: max-content !important;

}
.hooper-list:focus{
  outline: none;
}
.hooper-indicators {
  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.hooper-indicator {
  width: 8px;
  height: 8px;
  border-radius: 12px;
  margin: 0 8px;
    transition: 0.4s;
    &:focus{
      outline: none;
    }
}

.hooper-indicator.is-active,
.hooper-indicator:hover {
  background-color: #fff;
  width: 16px;
  height: 16px;
  transition: 0.4s;
}

.hooper-pagination {
  background: transparent;
  padding: 10px 10px;
right: 75%;

@media (max-width: $md){
  right: 50%;
top: 365px;
bottom: inherit;
}

@media (max-width: $sm){
  right: 50%;
top: 265px;
bottom: inherit;
}

}

.hooper-navigation button:hover{
  background-color: transparent;
}
.hooper-navigation button:focus{
  outline: none;
}

.hooper-next, .hooper-prev{
  width: 60px;
  padding: 0;


  svg{
    fill:#fff;
    width: 60px;
    height: 60px;
  }

}

.project-nav{
  position: relative;
  *:focus{
    outline: none;
  }

  .hooper-track{
    position: relative;
    z-index: 10;
  }
}
.project-nav::before{
  content: "";
  position: absolute;
  z-index: 11;
  left:0;
  top:0;
  bottom: 0;
  width: 10vw;
  height: 100%;
    pointer-events: none;
background: linear-gradient(90deg, rgba(255,255,255,0.88671218487394958) 0%, rgba(255,255,255,0) 100%);
}

.project-nav::after{
  content: "";
  position: absolute;
    z-index: 11;
  right:0;
  top:0;
  bottom: 0;
  width: 10vw;
  height: 100%;
  pointer-events: none;
background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.88671218487394958) 100%);
}

.project-nav{
  .is-current span{

    border-bottom: solid 2px;
  }
}

.project-nav{
  .swiper-slide{
    color: #3182ce;
    font-weight: 600;
      font-size: 16px;
&.swiper-slide-active span{
  border-bottom: solid 2px;
}

  }
}

.project-nav{
.hooper-next, .hooper-prev{
  z-index: 2;
  svg{
      fill:#fff;
      fill:transparent;
    }
  }
  .hooper-list{
    display: flex;
    justify-content: center;
  }
}
.main-slider{
@media (max-width: $md){
.hooper-navigation {
  top:40%;
  bottom: inherit;
}
}
}
