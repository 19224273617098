.partner-connect{
h2{
  margin-bottom: 6px;
}
  img{
    max-height: 60px;
  }
  p{
    margin: 6px 0;
  }
}

.multi-column{
  .individual-column:last-child {
    border: none;
  }

  .individual-column {
      flex: 1;
  }

  .become-fundraiser-events td {
      border-bottom: 1px solid;
      @apply border-gray-400;
      text-align: left;
      padding: 0.25rem;
  }

}

.quote{
  h1,h2,h3,h4,h5{
    margin: 0;
  }
}

.popup-sign-up {
  width: 500px;

  @media (max-width:$sm){
      width: 100vw;
  }
}

.normalcase {
    text-transform: none;
}
