// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
/* Customize the label (the container) */
.radio-option-contain{
  display: flex;

.radio-option {
  display: flex;
  position: relative;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
img{
  position: relative;
  z-index: 1;
}
.btn-bg{
  height: 100%;
  width:100%;
  position: absolute;
  z-index: 1;
}

img{
  opacity: 0.4;
}

  /* Hide the browser's default radio button */
   input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .btn-bg {
      opacity: 1;
    background-color: transparent;
  }

  &:hover input ~ img{
    opacity: 1;
  }
  /* When the radio button is checked, add a blue background */
  input:checked ~ .btn-bg {
    background-color: transparent;
    border-color: $brand-color;
  }

    input:checked ~ img {
    opacity: 1;
    }

  input:checked ~ .btn-bg:after{
    content: "";
    background-image: url('/images/static/tick.svg');
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    right:0;
    bottom: 0px;
    top:0;
    margin: auto;
  }

}
}

.arrow{
&_btn{

}

&_btn:hover{
  .arrow_left{
  transition: 0.2s;
  margin-left: 1.8em;
  }
}

  &_left{
    transition: 0.2s;
  }
}


#menu-content .active{
  border-bottom-width: 2px;
  border-bottom-color: $brand-color;
  color: $brand-color;
}

.btn {
  @extend %font-sans-regular;
  @include font-values(15,15);
  letter-spacing: 0.04em;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border: solid 1px;
  padding: 0 20px;
  border-radius: 3px;
  line-height: 45px;
  text-align: center;
  transition: all 0.6s ease-in-out;

  &:hover {
    background: #fff;
    color: #000;
  }

  &__white {
    color: #fff;
    border-color: #fff;
  }

  &__primary {
    background-color: $brand-color;
    border-color: $brand-color;
    color: #fff;
  }
}

.tabs {
  @extend %font-sans-regular;
  @include font-values(18,18);
}

p {
  a {
    color: $brand-color;
    border-bottom: 2px $brand-color solid;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
}

.btn_link {
  color: $brand-color;
  border-bottom: 2px $brand-color solid;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.close {
  font-size: 15px;
  letter-spacing: 0.04em;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 48px;
}

.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25rem;
  /* 20px */
  display: inline-block;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.no-js .inputfile + label {
  display: none;
}

.inputfile.has-focus + label,
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}
/* style 1 */
.inputfile-1 + label {
  color: #f1e5e6;
  background-color: #d3394c;
}

.inputfile-1 + label:hover,
.inputfile-1.has-focus + label,
.inputfile-1:focus + label {
  background-color: #722040;
}
/* style 2 */
.inputfile-2 + label {
  color: $brand-color;
  border: 2px solid currentColor;
}

.inputfile-2 + label:hover,
.inputfile-2.has-focus + label,
.inputfile-2:focus + label {
  color: $dark-bg;
}
