.dark-bg .hide-menu line{
  stroke: #1E2424;
}

.country-colour{
  color:#fff;
  border-color: #fff;
}

.dark-bg .country-colour{
  color:#1E2424;
  border-color: #1E2424;
}

/// Nav layout
.nav {
  ul {
    margin: 0 0 $pad 0;
    padding: 0;
  }
  .nav_link {
    font-size: 0.8em;
    font-weight: 500;

    &:first-child{
      border-style:solid;
      border-width: 1px;
      padding: 10px 8px;
      border-radius: 3px;
    }

    }

    @media screen and (max-width: 640px) {
      .nav_link {
        display: none;
        &:first-child{
          display: block;
          z-index: 10;
        }
      }
      }

  }


.nav-btn {
    display: none;
    margin-top: 0;
    height: 30px;
    width: 27px;
    position: absolute;
    left: $pad*1.5;
    top: 72px;
    cursor: pointer;
    text-transform: uppercase;
    img {
      margin: 7px 0;
    }
  }

  .nav_link{
    position: relative;
    z-index: 50;
  }

  .full-menu{
    height:700px;
    background-color: $dark-bg;
    -webkit-transition: all 500ms cubic-bezier(0.550, 0.010, 0.445, 0.990);
     -moz-transition: all 500ms cubic-bezier(0.550, 0.010, 0.445, 0.990);
       -o-transition: all 500ms cubic-bezier(0.550, 0.010, 0.445, 0.990);
          transition: all 500ms cubic-bezier(0.550, 0.010, 0.445, 0.990); /* custom */

    -webkit-transition-timing-function: cubic-bezier(0.550, 0.010, 0.445, 0.990);
       -moz-transition-timing-function: cubic-bezier(0.550, 0.010, 0.445, 0.990);
         -o-transition-timing-function: cubic-bezier(0.550, 0.010, 0.445, 0.990);
            transition-timing-function: cubic-bezier(0.550, 0.010, 0.445, 0.990); /* custom */

    &.header-menu-hide{
    }
    a{
      color:#fff;
    }

    @media screen and (max-width: 640px) {
      height: 100vh;
    }
  }


#middle-bottom{
transform:translate(0 7.75);
}
  .show-menu #middle-top{
    transform: rotate(45deg);
    transition:0.4s;
  }
  .show-menu #middle-bottom{
    transform: rotate(-45deg);
    transition:0.4s;
  }

  svg line{
    transition:0.4s;
  }

.show-menu {
  #bottom-line, #top-line{
    opacity:0;
    transition:0.2s;
  }
}
