/**
 * Basic typography style for copy text
 */
 // FONT CLASSES

%font-sans-regular {
  font-family: $regular-font-stack;
  font-weight: 300;
  font-style: normal;
  @include alias;
}

%font-sans-light {
  font-family: $regular-font-stack;
  font-weight: 200;
  font-style: normal;
  @include alias;
}

%font-sans-semi-bold {
  font-family: $regular-font-stack;
  font-weight: 600;
  font-style: normal;
  @include alias;
}

%font-sans-bold {
  font-family: $regular-font-stack;
  font-weight: 700;
  font-style: normal;
  @include alias;
}

%font-alt-regular {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 400;
  @include alias;
}

%font-alt-bold {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 700;
  @include alias;
}

body {
  background: #fff;
  color: $text-color;
  @extend %font-sans-regular;
  @include font-values(24,27);
  hyphens: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}
h1 {
  @extend %font-sans-bold;
  @include fluid-type(42px, 52px);
  margin-bottom: 30px;
  margin-top: 0px;

  &.banner-text{
      font-size: 4.1em;
      line-height: 0.9;
          @media screen and (max-width: $lg) {
      font-size: 2.6em;
          }
          @media screen and (max-width: $md) {
      font-size: 2.4em;
          }
          @media screen and (max-width: $sm) {
      font-size: 1.8em;
          }

          @media screen and (max-height: 700px) {
      font-size: 3em;
          }
  }

}

h2 {
  @extend %font-sans-semi-bold;
  @include fluid-type(24px, 34px);
  line-height: 1.4;

}
h3 {
  @extend %font-sans-bold;
  @include fluid-type(20px, 24px);
line-height: 1.4;
}

h4 {
    @extend %font-sans-semi-bold;
  @include font-values(21,21);
  line-height: 1.2;
&.banner-text{
  @media screen and (max-height: 700px) {
font-size: 0.8em;
  }
}
}

h5 {
  @include fluid-type(16px, 18px);
  line-height: 1.2;
}

h6{
  @extend %font-sans-bold;
  @include fluid-type(13px, 14px);
  line-height: 1.2;
}

.small {
  @include font-values(14,17);
}
.medium {
  @include font-values(16,18);
  line-height: 1.3;
}
.big {
  @include font-values(24,27);
  line-height: 1.3;
}
.huge {
  @include font-values(27,30);
  line-height: 1.3;
}
p {
  @include fluid-type(14px, 18px);
  margin-top: 0;
  font-weight: 400;
  line-height: 1.5;

}
ul {

    font-weight: 400;
}

.blog-wrapper ul {
    @include fluid-type(14px, 18px);
}
.page-description p{
    @include fluid-type(14px, 20px);
    font-weight: 500;
}

.donate-shrink .donate-field{
  @media screen and (max-height: 700px) {
font-size: 0.6em !important;
max-height: 30px !important;
  }
}

ul {
  padding-left: 15px;
}
strong {
  font-weight: bold;
}
// Typography helpers
.bold {
  @extend %font-alt-bold;
}
.sans {
  @extend %font-sans-regular;
}
.serif {
  @extend %font-alt-regular;
}
.upper {
  text-transform: uppercase;
}
