// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
  padding: $pad;
}

footer {
  .tabs {
    font-size: 14px;
    font-weight: 600;
  }
}

.block-contact p{
  font-size: 16px;
}

@media screen and (max-width:400px){
.legal-footer{
  display: block;
}
}
