/// Content area styles
#profile-pic-demo .drop-help-text {
  display: none;
}
#profile-pic-demo .is-drag-over .drop-help-text {
  display: block;
}
#profile-pic-demo .profile-pic-upload-block {
  border: 2px dashed transparent;
  padding: 20px;
  padding-top: 0;
}

#profile-pic-demo .is-drag-over.profile-pic-upload-block {
  border-color: #AAA;
}
#profile-pic-demo .vue-file-agent {
  width: 180px;
  float: left;
  margin: 0 15px 5px 0;
  border: 0;
  box-shadow: none;
}

.blog-img{
  position: relative;
}
.blog-img:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

.module-container, footer{
  position: relative;
  background: #fff;
}

.VideoBg {
  min-height: 640px;
}

.counter {
  height: 20vh;
  @media (max-height: 1000px) {
    height: inherit;
  }
}

.privacy-text > ol{
  list-style-type: decimal;
}

.privacy-text ol {
  counter-reset: item;
    padding-left: 50px;
}
.privacy-text ol li{
  display: block;

}
.privacy-text ol li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.privacy-text ol li ol li:before {
  content: " ";
}
.privacy-text ol li ol li ol li{
    list-style-type: lower-latin;
    display: list-item;
}

.privacy-text ol li ol li ol li ol li{
    list-style-type: lower-roman;
    display: list-item;

}

#loader {
  background: $brand-color;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

}

.banner-donation {
  background-color: rgba(0, 0, 0, 0.6);
}

.banner-donate-form {
  .tabs {
    font-size: 14px;
        font-weight: 600;
  }
}

.mob-bg-image {
  @media (max-width: 1024px) {
    min-height: 400px;

    &__info {
      top: -80px;
      margin-bottom: -80px;
    }
  }
}

.blue-overlay {
  background-color: rgba(24,117, 188, 1);
  @media (min-width: 1024px) {
    background-color: rgba(24,117, 188, 0.75);
  }
}
@media (min-width: 768px) {
  .h-short {
    height: 75vh;
  }
}

.h-post {
  height: 50vh;
}

ul {
  display: block;
  padding: 0;
  padding-inline-start: 1rem;

  li {
    margin: 0 0 1rem;
    font-size: inherit;
    vertical-align: top;
    @media screen and (max-width: $sm) {
    margin: 0 0 1.5rem;
    }
  }
}

.legal ul {
    padding: 0;
  li {
    font-size: unset;
  }
}

.thematics{
  ul{
    margin-top: 3rem;
    li{
      margin: 0 0 2rem;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.blog-section p {
  margin-block-end: 1.3em;
}

.blog-section ul {
  list-style: disc;
  margin: 20px 0;
  padding-left: 15px;

  li {
    margin: 10px;
    font-size: 18px;
  }
}

.what-we-do {
  ul {
    li {
      display: inline-block;
      width: 50%;
      @media screen and (max-width: 1024px) {
        display: block;
        width: 100%;
      }
    }
  }
}

.header-menu ul {
  margin: 0 0 0.8rem !important;

  li {
    margin: 0 0 0.5rem;
  }
}

.img-height {
  min-height: 400px;
  overflow: hidden;
  @media screen and (max-width: 640px) {
    min-height: 300px;
  }
}

.top-piece {
  padding-bottom: 16rem;
  @media screen and (max-width: 640px) {
    padding-bottom: 12rem;
  }
}

.top-overlay {
  position: relative;
  top: -180px;
  @media screen and (max-width: 640px) {
    top: -150px;
  }
}

.what-we-do {
  overflow-x: hidden;
}

.donate-banner {

}

.banner-amount{
  background-color: rgba(255, 255, 255, 0.2);
}

.top-overlay:after {
  content: "";
  width: 50vw;
  height: 100%;
  top: 0;
  position: absolute;
  left: 100%;
  background: #fff;
  @media screen and (max-width: 640px) {
    display: none;
  }
}

.donate{
  ul{
    li{
      @include fluid-type(14px, 18px);
    }
  }
}

@media screen and (min-width: 1024px) {
  .donate {
    .single_donate:nth-child(odd) {
      flex-direction: row;

      .single_donate_description {
        padding-right: 4rem;
      }
    }

    .single_donate:nth-child(even) {
      flex-direction: row-reverse;

      .single_donate_description {
        padding-left: 4rem;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .single_donate p {
    padding-top: 25px;
  }

  .donate .blog-img h2 {
    max-width: 300px;
    color: #fff;
  }

  .overlay-title {
    z-index: 5;
    top: -100px;

    &__trips {
      top: -60px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .donate .single_donate {
    flex-direction: column;
    flex: 50%;
  }
}

.grad-over {
  &-bottom {
    position: relative;
    z-index: 0;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,0.2721463585434174) 0%, rgba(0,0,0,0) 33%, rgba(0,0,0,0) 100%);
    }

    &-top {
      position: relative;
      z-index: 0;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0.3253676470588235) 0%, rgba(0,0,0,0) 21%, rgba(0,0,0,0) 82%, rgba(0,0,0,0.3253676470588235) 100%);
      }
    }
  }
}

.blog-wrapper figure {
    max-width: 800px;
    margin: 0 auto;
    margin-block-end: 1.3em;
}

.single_trip {
  @media screen and (min-width: 768px) {
    min-height: 800px;
  }
  @media screen and (max-width: 768px) {
    background-image: none !important;
  }
}

.header-menu-hide {
  transform: translateY(-110%);
}

.table td {
  text-transform: capitalize;
  letter-spacing: normal;
  text-align: left;
  line-height: normal;
}

.popup-inner {
  @media screen and (min-width: $sm) {
    max-height: 600px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  @media screen and (max-height: 699px) {
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    align-items: flex-start !important;
  }
  @media screen and (max-width: $sm) {
  height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

}

.popup-contain:after {
  content: "";
  position: absolute;
  left: 0;
  height: 60px;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
  @media screen and (max-height: 699px) {
    position: fixed;
  }
}

#loader-group {
  animation-name: loader;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  -webkit-transition-timing-function: cubic-bezier(0.505, 0, 0.135, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.505, -0.090, 0.135, 1.455);
  -moz-transition-timing-function: cubic-bezier(0.505, -0.090, 0.135, 1.455);
  -o-transition-timing-function: cubic-bezier(0.505, -0.090, 0.135, 1.455);
  transition-timing-function: cubic-bezier(0.505, -0.090, 0.135, 1.455);
  /* custom */
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


  @media screen and (max-width: 699px) {

  }

  .main-slider li, .multi-column li{
    list-style: none;
  }
